import { gql } from '@apollo/client/core';

export const FABRICATION_ORDERS_QUERY_LIST_BY_AREA = gql`
  query FabricationOrdersQueryList($area_id: Int!, $start: timestamp, $end: timestamp, $meter_id: Int!) {
    fabrication_orders(where: { area_id: { _eq: $area_id }, start: { _gte: $start, _lte: $end } }, order_by: { start: desc }) {
      start
      quantity
      id
      has_up_to_date_epi
      end
      client_fabrication_order_id
      area {
        name
        id
        fabrication_order_unit {
          symbol
        }
        production_unit {
          symbol
        }
      }
      fabrication_order_products {
        product {
          client_product_id
          id
        }
      }
      fabrication_order_epis(where: { meter_id: { _eq: $meter_id } }) {
        consumption_kwh
        cost
        cost_by_quantity
        epi
        id
        meter_id
      }
    }
  }
`;

export const FABRICATION_ORDERS_QUERY = gql`
  query FabricationOrdersQuery($id: Int!) {
    fabrication_orders_by_pk(id: $id) {
      id
      start
      quantity
      end
    }
  }
`;

export const FABRICATION_ORDERS_QUERY_BY_AREA = gql`
  query FabricationOrdersQueryByArea($area_id: Int!, $ids: [Int!]) {
    fabrication_orders(where: { area_id: { _eq: $area_id }, id: { _in: $ids } }, order_by: { start: desc }) {
      start
      id
      quantity
      end
      area {
        name
        id
        fabrication_order_unit {
          symbol
        }
        production_unit {
          symbol
        }
      }
      client_fabrication_order_id
      __typename
      fabrication_order_products {
        product {
          id
          client_product_id
          __typename
        }
      }
    }
  }
`;

export const FABRICATION_ORDER_INSERT = gql`
  mutation FabricationOrderInsert($fabrication_order: fabrication_orders_insert_input!) {
    insert_fabrication_orders_one(object: $fabrication_order) {
      start
      end
      quantity
      area_id
    }
  }
`;

export const FABRICATION_ORDER_UPDATE = gql`
  mutation FabricationOrderUpdate($fabrication_order: fabrication_orders_set_input!, $id: Int!) {
    update_fabrication_orders_by_pk(pk_columns: { id: $id }, _set: $fabrication_order) {
      start
      end
      quantity
      area {
        name
        id
        fabrication_order_unit {
          symbol
        }
        production_unit {
          symbol
        }
      }
      __typename
    }
  }
`;

export const FABRICATION_ORDER_DELETE = gql`
  mutation FabricationOrderDelete($id: Int!) {
    delete_fabrication_orders_by_pk(id: $id) {
      id
    }
  }
`;
